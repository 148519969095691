import { Controller } from "@hotwired/stimulus"
import handyScroll from "handy-scroll";

// Connects to data-controller="handy-scroll"
export default class extends Controller {
  connect() {
    console.log("connect")
    handyScroll.mount(this.element);

    // Sidebar resize fix
    new ResizeObserver(() => handyScroll.update(this.element))
      .observe(document.getElementById("page-content-wrapper"));
  }
}
