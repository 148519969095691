import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.element.dataset.refresh === "true") {
      setTimeout(() => {
        fetch(this.element.dataset.url, {
          method: "POST",
          headers: {Accept: "text/vnd.turbo-stream.html", "Content-Type": "application/json"},
          body: JSON.stringify({authenticity_token: document.querySelector("meta[name='csrf-token']").getAttribute("content")})
        })
          .then(r => r.text())
          .then(html => Turbo.renderStreamMessage(html));
      }, 3000);
    }
  }
}